<!-- 学校花名册 -->
<template>
     <div class="userList">
        <!-- top -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item >花名册管理</el-breadcrumb-item>
            <el-breadcrumb-item>学校列表</el-breadcrumb-item>
        </el-breadcrumb>
       
        <div class="content">
            <!-- tableTop -->
            <div class="tableTop">
                <div class="tableTop_left"></div>
                <div class="tableTop_right">
                    <div class="search">
                    <el-input
                            placeholder="请输入内容"
                            prefix-icon="el-icon-search"
                            @change="search"
                            v-model="seachcon"
                            class="elSerch"
                            >
                            <template slot="append" style="background-color: #00CCCC;cursor: pointer;">搜索</template>
                    </el-input>
                </div>
                <div class="delete">删除</div>
                </div>
            </div>
            <RosterTable/>
        </div>
     </div>
</template>
<script>
import RosterTable from '@/components/table/rosterTable.vue'
export default {
    beforeRouteLeave(to,from,next){
        from.meta.keepAlive = false;
        next();
    },
    components:{
        RosterTable,
    },
    data(){
        return{
            // 表头搜索框的任意搜索
             seachcon:null,
        }
    },
    methods:{
        // 开始查询
        search(){
            console.log("查询")
            
        },
       
    }
}
</script>
<style scoped lang="stylus">

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

/deep/.el-input__icon {
    line-height: 0 !important;
}

/deep/.el-icon-date:before{
    line-height: 0.2rem !important;
    font-size:0.11rem
}

/deep/.el-input__inner{
    height: .30rem !important;
    font-size: .11rem !important
  }
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
}
/deep/.el-button{
    padding: 0px 0 0 4px !important;
    text-align: center !important;
    color: #fff !important;
    font-size: 0.11rem !important;
  }
/deep/.el-date-editor .el-range-input{
    font-size: .11rem !important
}


// component style 
    //联合搜索框样式
.elSerch>>>.el-input__inner
    border: 1px solid #00CCCC !important
    width: 200px
    border-radius: 4px 0 0 4px

    
// 正常嵌套样式写法
.content
    overflow hidden
    position: relative
    margin-top: 16px
    padding: 20px
    background-color: #FFFFFF
  
    .tableTop 
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 20px
        .tableTop_left
            display: flex
        .tableTop_right
            display: flex
            .delete
                width: 64px
                height: 32px
                background-color: #FC5555
                text-align: center
                line-height:32px
                color: #fff
                font-size: 14px
                margin-left: 16px
                cursor pointer
                border-radius: 4px

</style>